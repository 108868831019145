
<template>
    <div>
        <transition name="slide">
            <div v-if="show" :class="['box', show ? 'slide-enter-active' : 'box slide-leave-active']"
                :style="{ height: navIndex == 0 ? '410px' : '445px' }">
                <div class="head">
                    <span>尊敬的用户 您好</span>
                    <img @click="close" src="/subject/components/loginDialog/wap/s2.png" alt="">
                </div>
                <div class="nav">
                    <div :class="navIndex == 0 ? 'item active' : 'item'" @click="navIndex = 0">登录</div>
                    <div :class="navIndex == 1 ? 'item active' : 'item'" @click="navIndex = 1">注册</div>
                </div>
                <div class="main">
                    <!-- 登录 -->
                    <div v-show="navIndex == 0">
                        <el-form ref="loginForm" :model="loginForm" :rules="loginFormRules">
                            <el-form-item prop="account">
                                <el-input v-model="loginForm.account" placeholder="请输入交易账号/手机号" prefix-icon="el-icon-mobile"
                                    maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item prop="pwd" :style="{ marginBottom: '5px' }">
                                <el-input v-model="loginForm.pwd" placeholder="请输入密码" prefix-icon="el-icon-lock"
                                    show-password maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-checkbox v-model="rememberPwd" @change="changeRememberPwd">记住密码</el-checkbox>
                                <!-- <a class="forgetPwd" href="https://www.rlcvipltd.net/uc/login/forgot-password"
                                    target="_blank">忘记密码</a> -->
                            </el-form-item>
                            <el-form-item :style="{ textAlign: 'center' }">
                                <el-button class="btn" @click="getLogin"
                                    :style="{ width: '280px', backgroundColor: '#f22118', border: '1px solid #f22118', color: '#fff' }">登录</el-button>
                            </el-form-item>
                        </el-form>
                        <div class="text">我们会严格保护您的数据安全</div>
                    </div>
                    <!-- 注册 -->
                    <div v-show="navIndex == 1">
                        <el-form ref="registerFormRef" :model="registerForm" :rules="registerFormRules">
                            <el-form-item prop="mobile">
                                <el-input v-model="registerForm.mobile" placeholder="请输入手机号" prefix-icon="el-icon-mobile"
                                    maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <el-input placeholder="请输入验证码" v-model="registerForm.code">
                                    <i slot="prefix" style="display: flex;align-items: center;">
                                        <img class="header-icon"
                                            style="width:20px;height:20px;margin-top: 10px;margin-left: 2px;"
                                            src="/subject/components/loginDialog/pc/s3.png" alt="" />
                                    </i>
                                    <el-button slot="append" @click="codeDialogShow" :disabled="codeDialogShowDisabled">{{
                                        codeDialogShowBtnText }}</el-button>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="pwd" :style="{ marginBottom: '5px' }">
                                <el-input v-model="registerForm.pwd" placeholder="设置密码(8-16位数字+字母)"
                                    prefix-icon="el-icon-lock" show-password maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item prop="read">
                                <el-checkbox v-model="registerForm.read">我已阅读和接受 <a style="color:#f22118"
                                        href="https://www.rlcvipltd.net/uc/userAgreement" target="_blank">《用户协议》</a><a
                                        style="color:#f22118" href="https://www.rlcvipltd.net/#/Privacy"
                                        target="_blank">《隐私政策》</a></el-checkbox>
                            </el-form-item>
                            <el-form-item :style="{ textAlign: 'center' }">
                                <el-button class="btn" @click="getRegister"
                                    :style="{ width: '280px', backgroundColor: '#f22118', border: '1px solid #f22118', color: '#fff' }">注册</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </transition>
        <div class="bg" v-show="bgShow"></div>
        <!-- 验证码弹框 -->
        <el-dialog title="验证码" :visible.sync="codeShow" width="300px" top="23%">
            <div class="codeBox">
                <img :src="codeImg" alt="">
                <div class="answer">
                    <el-input v-model="codeDialigVal" placeholder="请输入图片验证码的答案" maxlength="6"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="codeShow = false">取 消</el-button>
                <el-button @click="getSendCode"
                    :style="{ backgroundColor: '#f22118', border: '1px solid #f22118', color: '#fff' }">确
                    定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const Base64 = require("js-base64").Base64
import { getGuid } from '../../../../../../utils/guid';
import { getIMEI, appCode } from '../../../../../../utils/IMEI';
import { sendCode, register, rulerPhone, login } from '../../../../../../api/components/loginDialog';
export default {
    name: 'loginDialog',
    props: {
        loginDialogShow: {
            type: Boolean,
            required: false
        },
    },
    data() {
        var phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/
        var rulerPhone = (rule, value, callback) => {
            setTimeout(() => {
                if (!phoneReg.test(value)) {
                    callback(new Error('手机号格式有误'))
                } else {
                    callback()
                }
            }, 100)
        }
        var pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
        var rulerPwd = (rule, value, callback) => {
            setTimeout(() => {
                if (!pwdReg.test(value)) {
                    callback(new Error('密码格式有误'))
                } else {
                    callback()
                }
            }, 100)
        }
        return {
            show: this.loginDialogShow,
            bgShow: this.loginDialogShow,
            navIndex: 0,
            loginForm: {
                account: '',
                pwd: ''
            },
            rememberPwd: false,
            loginFormRules: {
                account: [
                    { required: true, message: '请输入交易账号/手机号', trigger: 'blur' }
                ],
                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ]
            },
            registerForm: {
                mobile: '',
                code: '',
                pwd: '',
                read: []
            },
            registerFormRules: {
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { required: true, validator: rulerPhone, trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { required: true, validator: rulerPwd, trigger: 'blur' }
                ],
                read: [
                    { type: 'array', required: true, message: '请先阅读并勾选相关政策', trigger: 'change' }
                ],
            },
            codeDialogShowDisabled: false,
            codeDialogShowBtnText: '获取验证码',
            codeShow: false,
            guid: '',
            codeImg: '',
            codeDialigVal: ''
        }
    },
    mounted() {
        this.getRememberPwd()
    },
    methods: {
        close() {
            this.show = false
            this.$emit('loginDialogShowChange', this.show);
            var _this = this
            setTimeout(function () {
                _this.bgShow = false
            }, 750)
        },
        navChange(index) {
            this.navIndex = index
        },
        //验证码弹窗
        codeDialogShow() {
            this.$refs.registerFormRef.validateField('mobile', (val) => {
                if (!val) {
                    rulerPhone(this.registerForm.mobile).then(res => {
                        if (res.msg.indexOf('已使用') > -1) {
                            return this.$message.error(this.registerForm.mobile + ' 已被使用');
                        }
                        this.guid = getGuid();
                        //this.codeImg = 'http://testwww.rlc525.com/api/proxy/v4/U104?key=' + this.guid
                        this.codeImg = 'https://www.rlcvipltd.net/api/proxy/v4/U104?key=' + this.guid
                        this.codeShow = true
                        this.codeDialigVal = ''
                    })
                }
            })
        },
        //发送验证码
        getSendCode() {
            if (this.codeDialigVal == '' || this.codeDialigVal == null || this.codeDialigVal == undefined) {
                return this.$message.error('请输入图片验证码的答案');
            }
            var params = {
                ImgCode: Number(this.codeDialigVal),
                Mobile: this.registerForm.mobile,
                Token: this.guid
            }
            var _this = this
            var time = 60
            sendCode(params).then(res => {
                if (res.ok != 1) {
                    this.$message.error(res.msg);
                } else if (res.ok == 1) {
                    this.$message.success('验证码发送成功');
                    this.codeDialogShowDisabled = true
                    this.codeDialogShowBtnText = time + 's'
                    setInterval(function () {
                        time = time - 1
                        if (time > 0) {
                            _this.codeDialogShowBtnText = time + 's'
                        } else {
                            _this.codeDialogShowBtnText = '获取验证码'
                            _this.codeDialogShowDisabled = false
                        }
                    }, 1000)
                }
                this.codeShow = false
            })

        },
        //注册
        getRegister() {
            this.$refs['registerFormRef'].validate((valid) => {
                if (valid) {
                    const searchString = window.sessionStorage.getItem('searchParams');
                    const searchParams = new URLSearchParams(searchString);
                    const exCode = searchParams.get('excode');
                    var params = {
                        Channel: appCode()['v'],
                        ClientId: getIMEI(),
                        Code: this.registerForm.code,
                        Email: '',
                        ExtensionAgentCode: exCode,
                        ImgCode: this.codeDialigVal,
                        IsQuickRregister: true,
                        LoginType: 1,
                        Mobile: this.registerForm.mobile,
                        Pwd: this.registerForm.pwd,
                        Token: this.guid
                    }
                    register(params).then(res => {
                        if (res.ok != 1) {
                            this.$message.error(JSON.parse(res.msg).Code)
                        } else if (res.ok == 1) {
                            this.$message.success('注册成功,正在自动登录');
                            var params = {
                                LoginName: res.data.Account,
                                LoginType: 1,
                                Pwd: res.data.Password
                            }
                            this.getLogin_After(params)
                        }
                    })
                }
            });
        },
        //登录
        getLogin() {
            this.$refs['loginForm'].validate((valid) => {
                if (valid) {
                    var params = {
                        LoginName: this.loginForm.account,
                        LoginType: 1,
                        Pwd: this.loginForm.pwd
                    }
                    this.getLogin_After(params)
                }
            });
        },
        //登录调接口
        getLogin_After(params) {
            login(params).then(res => {
                if (res.ok != 1) {
                    this.$message.error(res.msg)
                } else if (res.ok == 1) {
                    if (this.rememberPwd) {
                        window.localStorage.setItem('rememberPwd', true)
                        window.localStorage.setItem('userPwd', Base64.encode(params.Pwd))
                    }
                    this.$message.success('登录成功');
                    this.close()
                    window.localStorage.setItem('userAccount', res.data.Account)
                    window.sessionStorage.setItem('account', res.data.Account)
                    //this.$router.go(0)
                }
            })
        },
        //登录记住密码
        getRememberPwd() {
            if (window.localStorage.getItem('userAccount')) {
                this.loginForm.account = window.localStorage.getItem('userAccount')
            }
            if (window.localStorage.getItem('rememberPwd')) {
                this.rememberPwd = true
                this.loginForm.pwd = Base64.decode(window.localStorage.getItem('userPwd'))
            }
        },
        //改变 记住密码
        changeRememberPwd() {
            console.log(this.rememberPwd)
            if (this.rememberPwd) {
                window.localStorage.setItem('rememberPwd', true)
            } else {
                window.localStorage.removeItem('rememberPwd')
            }
        }
    },
    watch: {
        loginDialogShow() {
            this.show = this.loginDialogShow
            this.bgShow = this.loginDialogShow
        }
    }
}
</script>
<style lang="less" scoped>
.bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2001;
    width: 320px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    .head {
        width: 100%;
        height: 50px;
        background: url('/subject/components/loginDialog/wap/s1.png') no-repeat center;
        background-size: 100% 100%;

        span {
            color: #fff;
            font-size: 18px;
            line-height: 54px;
            margin-left: 20px;
        }

        img {
            height: 15px;
            float: right;
            margin-top: 8px;
            margin-right: 8px;
            cursor: pointer;
        }
    }

    .nav {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #f1f1f1;
        box-sizing: border-box;
        padding-left: 20px;

        .item {
            float: left;
            width: 40px;
            height: 46px;
            font-size: 17px;
            color: #959595;
            text-align: center;
            line-height: 50px;
            margin-right: 10px;
            cursor: pointer;
        }

        .active {
            color: #000;
            font-size: 17px;
            font-weight: 600;
            border-bottom: 3px solid #f22118;
        }
    }

    .main {
        width: 280px;
        margin: 0 auto;
        padding-top: 35px;

        .forgetPwd {
            font-size: 14px;
            color: #35a2ff;
            float: right;
        }

        .text {
            text-align: center;
            font-size: 14px;
            color: #c1c1c1;
        }
    }
}

.codeBox {
    width: 100%;

    img {
        display: block;
        width: 250px;
        height: 80px;
    }

    .answer {
        width: 250px;
        margin-top: 15px;
    }
}

.slide-enter-active {
    animation-name: slideOutDown;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.slide-leave-active {
    animation-name: slideInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes slideInUp {
    0% {
        opacity: 1;
        top: 50%;
    }

    to {
        opacity: 0;
        top: 55%;
    }
}

@keyframes slideOutDown {
    0% {
        opacity: 0;
        top: 55%;
    }

    to {
        opacity: 1;
        top: 50%;
    }
}
</style>
<style>
.el-input__inner:focus {
    border-color: #f22118;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #f22118 !important;
    background-color: #f22118 !important;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #f22118 !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #f22118 !important;
}

.el-button:focus,
.el-button:hover {
    color: rgba(242, 33, 24);
    border-color: rgba(242, 33, 24, .3);
    background-color: rgba(242, 33, 24, .1);
}

.el-dialog__headerbtn:focus .el-dialog__close {
    color: #f22118 !important;
}
</style>